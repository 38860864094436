import React, { useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import './FullScreenImage.css';

const FullScreenImage = ({name, img, alt, txt, realtext, subtext, yt, ex, links, showAnother}) => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  return (
    <div className="fullscreen-container">
      <img 
        src={img}
        alt={alt}
        className="fullscreen-image" 
      />
      <div className="moving-text">
        <div>
          <h1 className="hoaks-name">{name}</h1>
          <img className="scroll-down-arrow" src='down_arrow.png'/>
        </div>
      </div>
      <div>
        <div className="hoaks-explanation">
          <h2>What you heard</h2>
          <p>{txt}</p>
          <div className="hoaks-example">
            <p><a href={ex} target="_blank" rel='noreferrer'>Click here for an example</a></p>
          </div>
        </div>
        <div className="hoaks-explanation">
          <h2>What really happened</h2>
          <p>{realtext}</p>
          <div className="hoaks-youtube-evidence">
            <p>Here's what they don't want you to know:</p>
            <iframe width="560" height="315" frameBorder="0%" src={yt} 
                    title="I'm telling you.... it's Actually False" 
                    allow="autoplay; encrypted-media" 
                    referrerPolicy="strict-origin-when-cross-origin" 
                    allowFullScreen>        
            </iframe>
          </div>
        </div>
        <div className="hoaks-explanation">
          <p>So... {subtext}</p>
        </div>
      </div>
      <div className="show-another">
        <p>
          OK, maybe this one isn't true.  Click <span className="see-another-link" onClick={showAnother}>here </span>  
        to see another one they're lying to you about.</p>
      </div>
    </div>
  );
};

export default FullScreenImage;