import React, { useEffect, useState } from 'react';
import FullScreenImage from './FullScreenImage';

const Hoaks = () => {

    // h = the current hoax
    const [ hoaks , setHoaks ] = useState({});

    const theHoakses = [{
        name: 'Trump Colluded with Russia in the 2016 Election',
        img: 'hoaks/donald_trump_embraces_a_russian_flag.jpg',
        alt: `
          `,
        txt: `
          Throughout the 2016 election, the Trump campaign was aided by Russian actors and the 
          Russian government.  There is evidence that Russia colluded with the Trump campaign
          to affect the outcome of the election.
          `,
        realtext: `
          The definitive investigation into this entire series of corruption allegations
          is the Mueller Report, released in 2019 -- the product of two years of incredibly
          in-depth investigations (funded by the American tax payer).  At the conclusion of
          the Mueller investigation, they did not establish sufficient evidence to prove 
          that the campaign conspired or coordinated with Russia's election interference 
          efforts.  Mueller concluded that no criminal conspiracy between the Trump 
          campaign and Russia was established. 
          `,
        subtext: `
          They went after Trump with impeachment attempts twice, wasting millions of 
          taxpayer dollars in clearly partisan witch-hunt investigations, both times
          he was acquitted by the Senate.
          `,
        yt: 'https://www.youtube.com/embed/tBMqOzsNmFM?t=55s',
        ex: 'https://www.youtube.com/watch?v=ir0qNhLMX1s',
        links: []
      }, {
        name: 'Steele Dossier Exposed Trump and his Pee Pee Hookers',
        img: 'hoaks/pee_pee_hookers.jpg',
        alt: `
          The Steele Dossier was paid for by Hilary Clinton's campaign.  There is no known 
          evidence of the former president having a piss fetish.
          `,
        txt: `
          In opposition research turned up during the 2016 campaign, Michael Steele 
          found compromising information on Trump, including his thing for being 
          showered with pee by hookers (while in Russia, though it isn't clear if that is 
          part of the alleged fetish).  
          `,
        realtext: `
          Even the Mueller report disavowed all the content 
          included in the Steele dossier.  There is no evidence that Trump has a piss fetish -- 
          it's pretty unlikely, given his well established germ-a-phobia.
        `,
        subtext: `
          They used the Steele dossier for various nefarious purposes, including illegally 
          obtaining wiretaps on people surrounding Trump.
          `,
        yt: 'https://www.youtube.com/embed/lkeO6PeBp_E',
        ex: 'https://x.com/JohnWHuber/status/1129020581112877056',
        links: []
      }, {
        name: 'Trump Called Nazis Fine People',
        img: 'hoaks/fine_people.jpg',
        alt: `
          The videos of this are edited; in the full video Trump clearly specifies that he was NOT 
          talking about the neo-Nazis present.
          `,
        txt: `
          In 2016, a statue of Robert E. Lee was removed from its long-time location in 
          downtown Charlottesville, Virginia.  A number of neo-Nazi groups planned a march 
          dubbed 'Unite the Right' to coincide with the day the statue was to be taken down.  
          There were several violent altercations, one of which ended with a peaceful protestor 
          killed by a neo-Nazi.  Following the incident, Trump gave a press conference in which
          he said there were (including the neo-Nazis present) "very fine people on both sides".
          `,
        realtext: `
          There were basically three or four groups of people involved -- people at the site 
          of the statue demonstrating both for and against its removal, a separate group (of 
          neo-Nazis) attending  the 'Unite the Right' protest (at a different location), and 
          counter-protesters at that site.  Trump, when the video is viewed in full, clearly 
          states he is talking about the people protesting the removal of the statue and the 
          counter protests and NOT neo-Nazis.
        `,
        subtext: `
          They repeatedly claims Trump called neo-Nazis fine people.  He didn't; even the 
          left-leaning Snopes has given this one a "False".  But repeat it enough times and
          the guy is a racist.
          `,
        yt: 'https://www.youtube.com/embed/C4X9xNx7uzY',
        ex: 'https://www.youtube.com/watch?v=KQn9fxJWcJY&t=382s',
        links: []
      }, {
        name: 'Trump Said to Drink/Inject Bleach',
        img: 'hoaks/chug_bleach.jpg',
        alt: `
          `,
        txt: `
          At a press conference early in the Covid-19 pandemic, Trump said that people could
          just kill the virus by drinking or injecting bleach.
          `,
        realtext: `
          Trump talked about experiments being down with disinfecting ultraviolet light.  
          The unedited video makes it clear that he was not saying anything about 
          bleach at all; the 'disinfectant' he was talking about was, when the statement 
          is viewed in full, clearly a light source.
        `,
        subtext: `
          They continually push the lie that Trump thought injecting cleaning solution would 
          be a reasonable way to deal with Covid.  It is just another case of cutting video to 
          make someone seem to be saying something they are not.  Repeat it enough times and it's true!
          `,
        yt: 'https://www.youtube.com/embed/N0eDaYt413g',
        ex: 'https://www.youtube.com/watch?v=HUAMHc6Syjc',
        links: []
      }, {
        name: 'Trump Overfed the Koi Fish in Japan',
        img: 'hoaks/saturated_koi.jpg',
        alt: `
          `,
        txt: `
          President Trump and Japanese Prime Minister Abe were feeding Koi fish at the Akasaka palace 
          in Tokyo when Trump, being an orange menace on the world stage, aggressively and sneeringly 
          dumped all the fish food in his box at once. It is clear from the video this is an this 
          outrageous breach of etiquette and clear abuse of the sacred Koi.`,
        realtext: `
          However, if you watch the unedited video of the event, 
          Prime Minister Abe dumps his box of food first.  Taking his cue from his host, Trump did 
          the same.  What else would one do in that situation?
        `,
        subtext: `
          Trump was put into a situation where he had to make a split-second decision about how to
          react during a formal ceremony, apparently pretty seamlessly taking a cue from his host
          is a sign of... something.
          `,
        yt: 'https://www.youtube.com/embed/yAaFzb0sG3s?t=20s',
        ex: 'https://twitter.com/ReiMurasame/status/927398071847276544',
        links: []
      }, {
        name: 'Hunters Laptop Was Russian Disinfo',
        img: 'hoaks/sexy_beast.jpg',
        alt: `
          `,
        txt: `
          In the summer of 2020, a treasure trove of decadent pics, texts, and emails from 
          Joseph R. Biden's son Hunter surfaced.  In October 2020, immediately before the 
          presidential election, fifty-one 'former senior intelligence officials' released 
          a letter deeming the laptop had 'the classic earmarks of a Russian information operation'.
          The contents of the laptop (especially the emails) were fake, and an attempt by
          the Russians to manipulate the outcome of the US election.  
          `,
        realtext: `
          In April 2019, Hunter Biden dropped his laptop off at a repair shop and never came back to 
          retrieve it.  Since the owner (Hunter) never returned to retrieve the laptop, it became the 
          property of the business owned by Mac Isaac, who proceeded to provide the contents of the 
          laptop to Rudy Guiliani, who then turned them over to the New York Post.  Of course, it 
          turns out Russia had nothing to do with the laptop and it was, indeed, completely real and 
          not disinformation.
        `,
        subtext: `
          After years of claiming Russian interference in the upcoming 2020 election, deep state actors 
          interfered in the election by claiming something obviously real was fake and created by Russians.
          `,
        yt: 'https://www.youtube.com/embed/wZuk_FBf9Ck',
        ex: 'https://www.politico.com/news/2020/10/19/hunter-biden-story-russian-disinfo-430276',
        links: []
      }, {
        name: 'Trump Tried to Grab the Steering Wheel',
        img: 'hoaks/my_totally_not_lying_face.jpg',
        alt: `
          `,
        txt: `
          Cassidy Hutchinson, an aide to President Trump's chief-of-staff Mark Meadows, testified before 
          Congress that during the chaos of January 6th following Trump's speech to the group who would 
          later storm the Capitol building, Trump was being driven in his motorcade back to the White House
          when he lunged for the limousine's steering wheel in an attempt to get the driver to go instead 
          to the Capitol.  
          `,
        realtext: `
          Multiple Secret Service agents in the car at the time have come forward willing 
          to provide sworn testimony that the event never occurred.
        `,
        subtext: `
          Since the story about this alleged event emerged, they have used it as evidence that
          Donald Trump, once again, proved that his temperment and actions are unhinged.  What
          amounts to 'evidence' in this case has been refuted by multiple parties.  It does seem
          like a rather odd thing to do in a moving vehicle.
        `,
        yt: 'https://www.youtube.com/embed/TYw_HlgLAR0',
        ex: 'https://www.cbsnews.com/news/trump-steering-wheel-january-6-cassidy-hutchinson-testimony-mark-meadows/',
        links: []
      }, {
        name: 'Border Patrol Whipped Illegal Border Crossers',
        img: 'hoaks/here_comes_law_dog.jpg',
        alt: `
          `,
        txt: `
          In 2021, Customs and Border Patrol agents were patrolling the banks of the Rio Grande River near
          Del Rio, Texas when they confronted a group of Haitian migrants.  A photographer on the scene
          from Al Jazeera took several photographs showing the mounted officers confronting
          the illegals and driving them back with whips.
          
        `,
        realtext: `
          An official report by CBP Office of Professional Responsibility, citing interviews with agents 
          and journalists who were there when the photographs were taken, concluded there was "no evidence" 
          agents "involved in this incident struck, intentionally or otherwise, any migrant with their 
          reins." Members of the Horse Patrol Unit use split reins that can be swirled to direct their 
          horses.
        `,
        subtext: `
          When images taken by Al Jazeera showed CBP officers on horseback holding their horses' reins, 
          they immediately compared this to slavery since, you know, whips and stuff.  Even if it 
          is neither a whip nor being used as one.
          `,
        yt: 'https://www.youtube.com/embed/rqS3QvFF3TU?t=17s',
        ex: 'https://www.youtube.com/watch?v=sHmsxsIVsiw',
        links: []
      }];

      const updateHoaksIdx = () => {
        window.scrollTo(0,0);
        const idx = Math.floor(Math.random() * theHoakses.length);
        setHoaks(theHoakses[idx]);
      };
    
      // const idx = Math.floor(Math.random() * theHoakses.length);
      useEffect(() => {
        updateHoaksIdx();
        // setHoaks(theHoakses[idx]);
      }, []);
      
      return (
        <div>
          <FullScreenImage
            name={hoaks.name}
            img={hoaks.img}
            alt={hoaks.alt}
            txt={hoaks.txt}
            realtext={hoaks.realtext}
            subtext={hoaks.subtext}
            yt={hoaks.yt}
            ex={hoaks.ex}
            links={hoaks.links}
            showAnother={updateHoaksIdx}
          />
        </div>
    );
};

export default Hoaks;