import React from 'react';
import Hoaks from './components/Hoaks';

function App() {

  return (
    <div className="App">
      <Hoaks />
    </div>
  );
}

export default App;
